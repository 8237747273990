import React from 'react';
import styled from 'styled-components';

import { lighten } from 'polished';
import { PageProps } from 'gatsby';
import { Site } from '../components/Site';
import { SEO } from '../SEO';

export default (props: PageProps) => {
  return (
    <Site seo={SEO.fourOhFour} {...props}>
      <Bg>
        <H1>404: Page Not Found</H1>
      </Bg>
    </Site>
  );
};

const Bg = styled.main`
  background: ${({ theme }) =>
    `linear-gradient(101deg,${lighten(0.2, theme.colors.purple)} 0%, ${theme.colors.purple} 100%)`};
  height: 100vh;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const H1 = styled.h1`
  color: #fff;
  font-weight: 500;
`;
